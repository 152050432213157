export const Comment = () => import('../../components/Comment.vue' /* webpackChunkName: "components/comment" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const AboutCoreValue = () => import('../../components/About/CoreValue.vue' /* webpackChunkName: "components/about-core-value" */).then(c => wrapFunctional(c.default || c))
export const AboutFormBuySell = () => import('../../components/About/FormBuySell.vue' /* webpackChunkName: "components/about-form-buy-sell" */).then(c => wrapFunctional(c.default || c))
export const AboutMapCarpla = () => import('../../components/About/MapCarpla.vue' /* webpackChunkName: "components/about-map-carpla" */).then(c => wrapFunctional(c.default || c))
export const AboutMapInfo = () => import('../../components/About/MapInfo.vue' /* webpackChunkName: "components/about-map-info" */).then(c => wrapFunctional(c.default || c))
export const AboutTimeline = () => import('../../components/About/Timeline.vue' /* webpackChunkName: "components/about-timeline" */).then(c => wrapFunctional(c.default || c))
export const AboutTopAbout = () => import('../../components/About/TopAbout.vue' /* webpackChunkName: "components/about-top-about" */).then(c => wrapFunctional(c.default || c))
export const AboutVision = () => import('../../components/About/Vision.vue' /* webpackChunkName: "components/about-vision" */).then(c => wrapFunctional(c.default || c))
export const CarRelated = () => import('../../components/CarDetails/CarRelated.vue' /* webpackChunkName: "components/car-related" */).then(c => wrapFunctional(c.default || c))
export const CarDetailsDentMap = () => import('../../components/CarDetails/DentMap.vue' /* webpackChunkName: "components/car-details-dent-map" */).then(c => wrapFunctional(c.default || c))
export const CarDetailsDialogDetailInstallment = () => import('../../components/CarDetails/DialogDetailInstallment.vue' /* webpackChunkName: "components/car-details-dialog-detail-installment" */).then(c => wrapFunctional(c.default || c))
export const CarDetailsInstallmentPolicies = () => import('../../components/CarDetails/InstallmentPolicies.vue' /* webpackChunkName: "components/car-details-installment-policies" */).then(c => wrapFunctional(c.default || c))
export const CarDetailsInstallmentPolicies2 = () => import('../../components/CarDetails/InstallmentPolicies2.vue' /* webpackChunkName: "components/car-details-installment-policies2" */).then(c => wrapFunctional(c.default || c))
export const CarDetailsInterestedCar = () => import('../../components/CarDetails/InterestedCar.vue' /* webpackChunkName: "components/car-details-interested-car" */).then(c => wrapFunctional(c.default || c))
export const CarDetailsOtherCar = () => import('../../components/CarDetails/OtherCar.vue' /* webpackChunkName: "components/car-details-other-car" */).then(c => wrapFunctional(c.default || c))
export const CarDetailsOverviewSpecs = () => import('../../components/CarDetails/OverviewSpecs.vue' /* webpackChunkName: "components/car-details-overview-specs" */).then(c => wrapFunctional(c.default || c))
export const CarDetailsQualityCertified = () => import('../../components/CarDetails/QualityCertified.vue' /* webpackChunkName: "components/car-details-quality-certified" */).then(c => wrapFunctional(c.default || c))
export const CarDetailsSpecs = () => import('../../components/CarDetails/Specs.vue' /* webpackChunkName: "components/car-details-specs" */).then(c => wrapFunctional(c.default || c))
export const CarDetailsWarrantyNetwork = () => import('../../components/CarDetails/WarrantyNetwork.vue' /* webpackChunkName: "components/car-details-warranty-network" */).then(c => wrapFunctional(c.default || c))
export const CarDetailsWarrantyPolicies = () => import('../../components/CarDetails/WarrantyPolicies.vue' /* webpackChunkName: "components/car-details-warranty-policies" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/Banner/index.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const CarListBuyCarForm = () => import('../../components/CarList/BuyCarForm.vue' /* webpackChunkName: "components/car-list-buy-car-form" */).then(c => wrapFunctional(c.default || c))
export const CarBanner = () => import('../../components/CarList/CarBanner.vue' /* webpackChunkName: "components/car-banner" */).then(c => wrapFunctional(c.default || c))
export const CarListCompareModal = () => import('../../components/CarList/CompareModal.vue' /* webpackChunkName: "components/car-list-compare-modal" */).then(c => wrapFunctional(c.default || c))
export const CarListContactForm = () => import('../../components/CarList/ContactForm.vue' /* webpackChunkName: "components/car-list-contact-form" */).then(c => wrapFunctional(c.default || c))
export const CarListFilterBox = () => import('../../components/CarList/FilterBox.vue' /* webpackChunkName: "components/car-list-filter-box" */).then(c => wrapFunctional(c.default || c))
export const CarListFilterModal = () => import('../../components/CarList/FilterModal.vue' /* webpackChunkName: "components/car-list-filter-modal" */).then(c => wrapFunctional(c.default || c))
export const CarListFilterSidebar = () => import('../../components/CarList/FilterSidebar.vue' /* webpackChunkName: "components/car-list-filter-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CarListFooter = () => import('../../components/CarList/Footer.vue' /* webpackChunkName: "components/car-list-footer" */).then(c => wrapFunctional(c.default || c))
export const CarListSearchCarCard = () => import('../../components/CarList/SearchCarCard.vue' /* webpackChunkName: "components/car-list-search-car-card" */).then(c => wrapFunctional(c.default || c))
export const CarListSearchCarForm = () => import('../../components/CarList/SearchCarForm.vue' /* webpackChunkName: "components/car-list-search-car-form" */).then(c => wrapFunctional(c.default || c))
export const CarListSuggestSearchModal = () => import('../../components/CarList/SuggestSearchModal.vue' /* webpackChunkName: "components/car-list-suggest-search-modal" */).then(c => wrapFunctional(c.default || c))
export const DescriptionPopup = () => import('../../components/DescriptionPopup/DescriptionPopup.vue' /* webpackChunkName: "components/description-popup" */).then(c => wrapFunctional(c.default || c))
export const DialogOffer = () => import('../../components/Dialog/DialogOffer.vue' /* webpackChunkName: "components/dialog-offer" */).then(c => wrapFunctional(c.default || c))
export const DialogSignInDialog = () => import('../../components/Dialog/SignInDialog.vue' /* webpackChunkName: "components/dialog-sign-in-dialog" */).then(c => wrapFunctional(c.default || c))
export const ElementsCButton = () => import('../../components/Elements/CButton.vue' /* webpackChunkName: "components/elements-c-button" */).then(c => wrapFunctional(c.default || c))
export const ElementsChatButton = () => import('../../components/Elements/ChatButton.vue' /* webpackChunkName: "components/elements-chat-button" */).then(c => wrapFunctional(c.default || c))
export const ElementsCurrencyInput = () => import('../../components/Elements/CurrencyInput.vue' /* webpackChunkName: "components/elements-currency-input" */).then(c => wrapFunctional(c.default || c))
export const ElementsDialogSearchCarV2 = () => import('../../components/Elements/DialogSearchCarV2.vue' /* webpackChunkName: "components/elements-dialog-search-car-v2" */).then(c => wrapFunctional(c.default || c))
export const ElementsDialogSubmitError = () => import('../../components/Elements/DialogSubmitError.vue' /* webpackChunkName: "components/elements-dialog-submit-error" */).then(c => wrapFunctional(c.default || c))
export const ElementsDialogValuation = () => import('../../components/Elements/DialogValuation.vue' /* webpackChunkName: "components/elements-dialog-valuation" */).then(c => wrapFunctional(c.default || c))
export const ElementsLanguageBox = () => import('../../components/Elements/LanguageBox.vue' /* webpackChunkName: "components/elements-language-box" */).then(c => wrapFunctional(c.default || c))
export const ElementsRenderlessWithClickOutside = () => import('../../components/Elements/RenderlessWithClickOutside.vue' /* webpackChunkName: "components/elements-renderless-with-click-outside" */).then(c => wrapFunctional(c.default || c))
export const ElementsSlickCarousel = () => import('../../components/Elements/SlickCarousel.vue' /* webpackChunkName: "components/elements-slick-carousel" */).then(c => wrapFunctional(c.default || c))
export const ElementsTooltip = () => import('../../components/Elements/Tooltip.vue' /* webpackChunkName: "components/elements-tooltip" */).then(c => wrapFunctional(c.default || c))
export const ElementsUnderline = () => import('../../components/Elements/Underline.vue' /* webpackChunkName: "components/elements-underline" */).then(c => wrapFunctional(c.default || c))
export const ElementsViewAll = () => import('../../components/Elements/ViewAll.vue' /* webpackChunkName: "components/elements-view-all" */).then(c => wrapFunctional(c.default || c))
export const FAQDialogRaiseFAQ = () => import('../../components/FAQ/DialogRaiseFAQ.vue' /* webpackChunkName: "components/f-a-q-dialog-raise-f-a-q" */).then(c => wrapFunctional(c.default || c))
export const FAQDialogSuccessFAQ = () => import('../../components/FAQ/DialogSuccessFAQ.vue' /* webpackChunkName: "components/f-a-q-dialog-success-f-a-q" */).then(c => wrapFunctional(c.default || c))
export const FAQFAQGeneral = () => import('../../components/FAQ/FAQGeneral.vue' /* webpackChunkName: "components/f-a-q-f-a-q-general" */).then(c => wrapFunctional(c.default || c))
export const FAQFieldsetFAQItem = () => import('../../components/FAQ/FieldsetFAQItem.vue' /* webpackChunkName: "components/f-a-q-fieldset-f-a-q-item" */).then(c => wrapFunctional(c.default || c))
export const FAQFieldsetFAQResponse = () => import('../../components/FAQ/FieldsetFAQResponse.vue' /* webpackChunkName: "components/f-a-q-fieldset-f-a-q-response" */).then(c => wrapFunctional(c.default || c))
export const FAQTabFAQItem = () => import('../../components/FAQ/TabFAQItem.vue' /* webpackChunkName: "components/f-a-q-tab-f-a-q-item" */).then(c => wrapFunctional(c.default || c))
export const FeedbackCertifiedItem = () => import('../../components/Feedback/FeedbackCertifiedItem.vue' /* webpackChunkName: "components/feedback-certified-item" */).then(c => wrapFunctional(c.default || c))
export const FeedbackItem = () => import('../../components/Feedback/FeedbackItem.vue' /* webpackChunkName: "components/feedback-item" */).then(c => wrapFunctional(c.default || c))
export const FeedbackSellCarItem = () => import('../../components/Feedback/FeedbackSellCarItem.vue' /* webpackChunkName: "components/feedback-sell-car-item" */).then(c => wrapFunctional(c.default || c))
export const HlsVideo = () => import('../../components/HlsVideo/HlsVideo.vue' /* webpackChunkName: "components/hls-video" */).then(c => wrapFunctional(c.default || c))
export const HomeBanner = () => import('../../components/Home/Banner.vue' /* webpackChunkName: "components/home-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeBoxHTML = () => import('../../components/Home/BoxHTML.vue' /* webpackChunkName: "components/home-box-h-t-m-l" */).then(c => wrapFunctional(c.default || c))
export const HomeBrand = () => import('../../components/Home/Brand.vue' /* webpackChunkName: "components/home-brand" */).then(c => wrapFunctional(c.default || c))
export const HomeFeature = () => import('../../components/Home/Feature.vue' /* webpackChunkName: "components/home-feature" */).then(c => wrapFunctional(c.default || c))
export const HomeFeatureCard = () => import('../../components/Home/FeatureCard.vue' /* webpackChunkName: "components/home-feature-card" */).then(c => wrapFunctional(c.default || c))
export const HomeFeedback = () => import('../../components/Home/Feedback.vue' /* webpackChunkName: "components/home-feedback" */).then(c => wrapFunctional(c.default || c))
export const HomeFeedbackCard = () => import('../../components/Home/FeedbackCard.vue' /* webpackChunkName: "components/home-feedback-card" */).then(c => wrapFunctional(c.default || c))
export const HomeNews = () => import('../../components/Home/News.vue' /* webpackChunkName: "components/home-news" */).then(c => wrapFunctional(c.default || c))
export const HomePartner = () => import('../../components/Home/Partner.vue' /* webpackChunkName: "components/home-partner" */).then(c => wrapFunctional(c.default || c))
export const HomePopup = () => import('../../components/Home/Popup.vue' /* webpackChunkName: "components/home-popup" */).then(c => wrapFunctional(c.default || c))
export const HomeService = () => import('../../components/Home/Service.vue' /* webpackChunkName: "components/home-service" */).then(c => wrapFunctional(c.default || c))
export const HomeServiceCard = () => import('../../components/Home/ServiceCard.vue' /* webpackChunkName: "components/home-service-card" */).then(c => wrapFunctional(c.default || c))
export const HomeShop = () => import('../../components/Home/Shop.vue' /* webpackChunkName: "components/home-shop" */).then(c => wrapFunctional(c.default || c))
export const HomeNewLoginComponents = () => import('../../components/HomeNew/LoginComponents.vue' /* webpackChunkName: "components/home-new-login-components" */).then(c => wrapFunctional(c.default || c))
export const HomeNewOther1 = () => import('../../components/HomeNew/Other1.vue' /* webpackChunkName: "components/home-new-other1" */).then(c => wrapFunctional(c.default || c))
export const HomeNewSearchInputContainer = () => import('../../components/HomeNew/SearchInputContainer.vue' /* webpackChunkName: "components/home-new-search-input-container" */).then(c => wrapFunctional(c.default || c))
export const HomeNewService = () => import('../../components/HomeNew/Service.vue' /* webpackChunkName: "components/home-new-service" */).then(c => wrapFunctional(c.default || c))
export const HomeNewServiceItem = () => import('../../components/HomeNew/ServiceItem.vue' /* webpackChunkName: "components/home-new-service-item" */).then(c => wrapFunctional(c.default || c))
export const HomeNewServiceItem2 = () => import('../../components/HomeNew/ServiceItem2.vue' /* webpackChunkName: "components/home-new-service-item2" */).then(c => wrapFunctional(c.default || c))
export const LayoutsBreadcrumbCard = () => import('../../components/Layouts/BreadcrumbCard.vue' /* webpackChunkName: "components/layouts-breadcrumb-card" */).then(c => wrapFunctional(c.default || c))
export const LightboxApp = () => import('../../components/Lightbox/App.vue' /* webpackChunkName: "components/lightbox-app" */).then(c => wrapFunctional(c.default || c))
export const Lightbox = () => import('../../components/Lightbox/index.js' /* webpackChunkName: "components/lightbox" */).then(c => wrapFunctional(c.default || c))
export const LightboxMain = () => import('../../components/Lightbox/main.js' /* webpackChunkName: "components/lightbox-main" */).then(c => wrapFunctional(c.default || c))
export const LoginMethods = () => import('../../components/LoginMethods/LoginMethods.vue' /* webpackChunkName: "components/login-methods" */).then(c => wrapFunctional(c.default || c))
export const NewsNewCategoryItem = () => import('../../components/News/NewCategoryItem.vue' /* webpackChunkName: "components/news-new-category-item" */).then(c => wrapFunctional(c.default || c))
export const NewsCard = () => import('../../components/News/NewsCard.vue' /* webpackChunkName: "components/news-card" */).then(c => wrapFunctional(c.default || c))
export const NewsEvents = () => import('../../components/News/NewsEvents.vue' /* webpackChunkName: "components/news-events" */).then(c => wrapFunctional(c.default || c))
export const NewsItem = () => import('../../components/News/NewsItem.vue' /* webpackChunkName: "components/news-item" */).then(c => wrapFunctional(c.default || c))
export const NewsItemCertified = () => import('../../components/News/NewsItemCertified.vue' /* webpackChunkName: "components/news-item-certified" */).then(c => wrapFunctional(c.default || c))
export const NewsItemHome = () => import('../../components/News/NewsItemHome.vue' /* webpackChunkName: "components/news-item-home" */).then(c => wrapFunctional(c.default || c))
export const NewCategoryItem = () => import('../../components/NewNews/NewCategoryItem.vue' /* webpackChunkName: "components/new-category-item" */).then(c => wrapFunctional(c.default || c))
export const NewNewsCard = () => import('../../components/NewNews/NewsCard.vue' /* webpackChunkName: "components/new-news-card" */).then(c => wrapFunctional(c.default || c))
export const NewNewsItem = () => import('../../components/NewNews/NewsItem.vue' /* webpackChunkName: "components/new-news-item" */).then(c => wrapFunctional(c.default || c))
export const NewNewsItemCertified = () => import('../../components/NewNews/NewsItemCertified.vue' /* webpackChunkName: "components/new-news-item-certified" */).then(c => wrapFunctional(c.default || c))
export const NewNewsItemHome = () => import('../../components/NewNews/NewsItemHome.vue' /* webpackChunkName: "components/new-news-item-home" */).then(c => wrapFunctional(c.default || c))
export const NewNewsOutstandingNews = () => import('../../components/NewNews/OutstandingNews.vue' /* webpackChunkName: "components/new-news-outstanding-news" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/Notification/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const PopupAds = () => import('../../components/Popup/PopupAds.vue' /* webpackChunkName: "components/popup-ads" */).then(c => wrapFunctional(c.default || c))
export const ProductInterestedProductItem = () => import('../../components/Product/InterestedProductItem.vue' /* webpackChunkName: "components/product-interested-product-item" */).then(c => wrapFunctional(c.default || c))
export const ProductItem = () => import('../../components/Product/ProductItem.vue' /* webpackChunkName: "components/product-item" */).then(c => wrapFunctional(c.default || c))
export const ProductList = () => import('../../components/Product/ProductList.vue' /* webpackChunkName: "components/product-list" */).then(c => wrapFunctional(c.default || c))
export const ProductSoldItem = () => import('../../components/Product/ProductSoldItem.vue' /* webpackChunkName: "components/product-sold-item" */).then(c => wrapFunctional(c.default || c))
export const RecruitItem = () => import('../../components/Recruit/RecruitItem.vue' /* webpackChunkName: "components/recruit-item" */).then(c => wrapFunctional(c.default || c))
export const SellCarEasySellCar = () => import('../../components/SellCar/EasySellCar.vue' /* webpackChunkName: "components/sell-car-easy-sell-car" */).then(c => wrapFunctional(c.default || c))
export const SellCarEasySellCarForUpgrade = () => import('../../components/SellCar/EasySellCarForUpgrade.vue' /* webpackChunkName: "components/sell-car-easy-sell-car-for-upgrade" */).then(c => wrapFunctional(c.default || c))
export const SellCarEasySellCarItem = () => import('../../components/SellCar/EasySellCarItem.vue' /* webpackChunkName: "components/sell-car-easy-sell-car-item" */).then(c => wrapFunctional(c.default || c))
export const SellCarFooter = () => import('../../components/SellCar/Footer.vue' /* webpackChunkName: "components/sell-car-footer" */).then(c => wrapFunctional(c.default || c))
export const SellCarLicensePlateNumber = () => import('../../components/SellCar/LicensePlateNumber.vue' /* webpackChunkName: "components/sell-car-license-plate-number" */).then(c => wrapFunctional(c.default || c))
export const SellCarMapSellCar = () => import('../../components/SellCar/MapSellCar.vue' /* webpackChunkName: "components/sell-car-map-sell-car" */).then(c => wrapFunctional(c.default || c))
export const SellCarWhyChooseUs = () => import('../../components/SellCar/WhyChooseUs.vue' /* webpackChunkName: "components/sell-car-why-choose-us" */).then(c => wrapFunctional(c.default || c))
export const ServiceItem = () => import('../../components/Service/ServiceItem.vue' /* webpackChunkName: "components/service-item" */).then(c => wrapFunctional(c.default || c))
export const StatisticsHomeItem = () => import('../../components/Statistics/StatisticsHomeItem.vue' /* webpackChunkName: "components/statistics-home-item" */).then(c => wrapFunctional(c.default || c))
export const StatisticsHomeList = () => import('../../components/Statistics/StatisticsHomeList.vue' /* webpackChunkName: "components/statistics-home-list" */).then(c => wrapFunctional(c.default || c))
export const TransitionGuestModal = () => import('../../components/TransitionGuestModal/TransitionGuestModal.vue' /* webpackChunkName: "components/transition-guest-modal" */).then(c => wrapFunctional(c.default || c))
export const Vue360I360Viewer = () => import('../../components/Vue360/I360Viewer.vue' /* webpackChunkName: "components/vue360-i360-viewer" */).then(c => wrapFunctional(c.default || c))
export const BannerPrimaryBannerMediumBannerItem = () => import('../../components/Banner/PrimaryBanner/MediumBannerItem.vue' /* webpackChunkName: "components/banner-primary-banner-medium-banner-item" */).then(c => wrapFunctional(c.default || c))
export const BannerPrimaryBannerItem = () => import('../../components/Banner/PrimaryBanner/PrimaryBannerItem.vue' /* webpackChunkName: "components/banner-primary-banner-item" */).then(c => wrapFunctional(c.default || c))
export const BannerPrimaryBannerList = () => import('../../components/Banner/PrimaryBanner/PrimaryBannerList.vue' /* webpackChunkName: "components/banner-primary-banner-list" */).then(c => wrapFunctional(c.default || c))
export const BannerPrimaryBannerSecondaryBannerItem = () => import('../../components/Banner/PrimaryBanner/SecondaryBannerItem.vue' /* webpackChunkName: "components/banner-primary-banner-secondary-banner-item" */).then(c => wrapFunctional(c.default || c))
export const BannerSmallBannerItem = () => import('../../components/Banner/SmallBannerList/SmallBannerItem.vue' /* webpackChunkName: "components/banner-small-banner-item" */).then(c => wrapFunctional(c.default || c))
export const BannerSmallBannerList = () => import('../../components/Banner/SmallBannerList/SmallBannerList.vue' /* webpackChunkName: "components/banner-small-banner-list" */).then(c => wrapFunctional(c.default || c))
export const FAQTabPanelFAQResponse = () => import('../../components/FAQ/TabPanel/FAQResponse.vue' /* webpackChunkName: "components/f-a-q-tab-panel-f-a-q-response" */).then(c => wrapFunctional(c.default || c))
export const FAQTabFAQBuyCar = () => import('../../components/FAQ/TabPanel/TabFAQBuyCar.vue' /* webpackChunkName: "components/f-a-q-tab-f-a-q-buy-car" */).then(c => wrapFunctional(c.default || c))
export const FAQTabFAQGeneral = () => import('../../components/FAQ/TabPanel/TabFAQGeneral.vue' /* webpackChunkName: "components/f-a-q-tab-f-a-q-general" */).then(c => wrapFunctional(c.default || c))
export const FAQTabFAQSellCar = () => import('../../components/FAQ/TabPanel/TabFAQSellCar.vue' /* webpackChunkName: "components/f-a-q-tab-f-a-q-sell-car" */).then(c => wrapFunctional(c.default || c))
export const LightboxComponentsCoolLightBox = () => import('../../components/Lightbox/components/CoolLightBox.vue' /* webpackChunkName: "components/lightbox-components-cool-light-box" */).then(c => wrapFunctional(c.default || c))
export const LightboxDirectivesAutoplayObserver = () => import('../../components/Lightbox/directives/AutoplayObserver.js' /* webpackChunkName: "components/lightbox-directives-autoplay-observer" */).then(c => wrapFunctional(c.default || c))
export const LightboxDirectivesLazyLoad = () => import('../../components/Lightbox/directives/LazyLoad.js' /* webpackChunkName: "components/lightbox-directives-lazy-load" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
